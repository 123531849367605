import { CompleteTask } from "@/api";
import { mapGetters } from "vuex";

export const myMixins = {
  computed: {
    ...mapGetters(["isLogin", "userInfo", "netBarId"]),
  },
  data() {
    return {};
  },
  created() {
    if (
      this.$route.query.from &&
      this.$route.query.from == "signin" &&
      this.$route.query.typeId
    ) {
      this._CompleteTask(this.$route.query.typeId);
    }
  },
  methods: {
    // 任务赚积分
    async _CompleteTask(typeId) {
      console.log("typeID", typeId);
      if (this.userInfo && this.userInfo.ConsumerId && this.netBarId) {
        let params = {
          UserId: this.userInfo.ConsumerId,
          NetbarId: parseInt(this.netBarId),
          Type: parseInt(typeId),
        };
        let res = await CompleteTask(params);
        if (res.StatusCode == 200) {
          setTimeout(() => {
            // this.$message.success('恭喜完成积分任务')
            this.$confirm("恭喜完成积分任务！是否继续任务？", "提示：", {
              confirmButtonText: "完成下一项任务",
              cancelButtonText: "留在当前页面",
              closeOnClickModal: false,
            })
              .then(() => {
                this.$router.back();
              })
              .catch(() => {});
          }, 1000);
        }
      }
    },
  },
};
